<template>
  <div class="h-100">
    <DxValidationGroup>
      <div class="row">
        <div class="col-lg-12">
          <strong>Перемещение в ССП. {{ name }}</strong>
          <strong class="ps-1 text-danger" v-if="isExistAgreement">ВНИМАНИЕ! Действует соглашение</strong>
          <hr class="my-1" />
        </div>
      </div>
      <DxValidationSummary />

      <div class="row mt-3">
        <div class="col-md-6">
          <div class="d-flex align-items-center">
            <DxButton class="w-100" type="default" text="Скопировать данные из решения" @click="copyAdditionalInfo" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="d-flex align-items-center">
            <DxRadioGroup layout="horizontal" display-expr="text" value-expr="value" :data-source="solidarity" v-model="isSolidarity" />
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-6">
          <label class="form-label mb-0 me-2 text-nowrap">Дата передачи:</label>
          <DxDateBox v-model="sendDate" :show-clear-button="true" :use-mask-behavior="true" placeholder="введите или выберите" />
        </div>
        <div class="col-md-6">
          <label class="form-label mb-0 me-2 text-nowrap">Номер исполнительного листа:</label>
          <DxTextBox v-model="executionDocNo" :show-clear-button="true" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <label class="form-label mb-0 me-2 text-nowrap">Переданная сумма:</label>
          <DxNumberBox v-model="deliveredSum" :show-clear-button="true" ref="tbDeliveredSum" format="#,##0.00" />
        </div>
        <div class="col-md-6">
          <div class="mb-0 alert" :class="{ 'alert-primary': isActualDebtMore, 'alert-danger': !isActualDebtMore }" ref="lblActualDebts">
            Долг на {{ debtsData.actualDebtsGrouped.actualDate }}: {{ f(debtsData.actualDebtsGrouped.debtsAll) }} руб.
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <label class="form-label mb-0 me-2 text-nowrap">Пени:</label>
          <DxNumberBox v-model="penalties" :show-clear-button="true" ref="tbPenalties" format="#,##0.00" />
        </div>
        <div class="col-md-6">
          <label class="form-label mb-0 me-2 text-nowrap">Гос. пошлина:</label>
          <DxNumberBox v-model="stateFee" :show-clear-button="true" ref="tbStateFee" format="#,##0.00" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <label class="form-label mb-0 me-2">Статус:</label>
          <DxSelectBox
            ref="cmbSsp"
            v-model="sspStatusId"
            display-expr="name"
            value-expr="id"
            class="w-100"
            :data-source="sspStatusesStore"
            :show-clear-button="true"
          >
            <DxValidator>
              <DxRequiredRule :message="'Поле ССП обязательное'" />
            </DxValidator>
          </DxSelectBox>
        </div>
        <div class="col-md-6">
          <label class="form-label mb-0 me-2 text-nowrap">Номер исполнительного производства:</label>
          <DxTextBox :show-clear-button="true" v-model="executionDocNumber" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <label class="form-label mb-0 me-2">Дата возбуждения:</label>
          <DxDateBox
            :show-clear-button="true"
            v-model="enforcementStartDate"
            :use-mask-behavior="true"
            placeholder="введите или выберите"
          />
        </div>
        <div class="col-md-6">
          <label class="form-label mb-0 me-2 text-nowrap">Дата окончания:</label>
          <DxDateBox
            :show-clear-button="true"
            v-model="enforcementFinishDate"
            :use-mask-behavior="true"
            placeholder="введите или выберите"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <label class="form-label mb-0 me-2">ФИО пристава:</label>
          <DxTextBox :show-clear-button="true" v-model="bailiffName" />
        </div>
        <div class="col-md-6">
          <label class="form-label mb-0 me-2 text-nowrap">Отдел ССП:</label>
          <DxTextBox :show-clear-button="true" v-model="bailiff" ref="tbBailiff" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <label class="form-label mb-0 me-2">Примечание:</label>
          <textarea type="text" class="form-control form-control-sm" v-model="notes" rows="3" />
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-12">
          <div class="d-flex align-items-center">
            <label class="form-label mb-0 me-2">Шаблон:</label>
            <DxSelectBox
              ref="cmbTemplates"
              display-expr="name"
              value-expr="fileId"
              v-model="templateId"
              :data-source="templatesStore"
              :show-clear-button="true"
              class="w-100"
            ></DxSelectBox>
          </div>
        </div>
      </div>

      <div class="row mt-2" v-if="documentId != null">
        <div class="col-md-12">
          <DxButton class="btn-label b primary p-2" @click="downloadDocument">
            <div class="d-flex">
              <span class="label-icon">
                <DownloadIcon class="i" />
              </span>
              <span class="ms-4"> Скачать заявление </span>
            </div>
          </DxButton>
          <span class="align-middle text-success"> сформировано {{ fdt(doc.created) }} </span>
        </div>
      </div>

      <div class="form-row row mt-2" v-if="canGenerateDoc">
        <div class="form-group col-md-8">
          <DxButton class="btn-label b primary p-2" @click="generateDocument">
            <div class="d-flex">
              <span class="label-icon">
                <FileTextIcon class="i" />
              </span>
              <span class="ms-4">Сформировать печатную форму заявления </span>
            </div>
          </DxButton>
        </div>
      </div>
      <div class="row mt-2">
        <div class="d-flex gap-3">
          <button class="btn btn-danger btn-sm ms-auto" @click="cancel">Отменить</button>
          <DxButton class="btn-success" id="btnSavePfr" text="Сохранить" @click="save" />
        </div>
      </div>
    </DxValidationGroup>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { FileTextIcon, DownloadIcon } from "@zhuowenli/vue-feather-icons";
import DxValidationSummary from "devextreme-vue/validation-summary";
import DxRadioGroup from "devextreme-vue/radio-group";
import DxValidationGroup from "devextreme-vue/validation-group";
import createStoreExtend from "@/helpers/grid/store";
import { sspStatusesStore } from "@/helpers/common-store/store";
import { formatNumber } from "devextreme/localization";
import { useWritCardExecutionStore } from "../../../state/writ.card.execution";
import { storeToRefs } from "pinia";
import { formatDate } from "devextreme/localization";
import { blink } from "@/helpers";

export default {
  components: {
    DxRadioGroup,
    DxValidationSummary,
    DxValidationGroup,
    FileTextIcon,
    DownloadIcon,
  },
  props: {
    item: {
      type: Object,
    },

    additionalInfo: {
      type: Object,
    },

    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["cancel", "save", "generate", "download"],
  setup() {
    const store = useWritCardExecutionStore();
    const { executionDocumentsSsp } = storeToRefs(store);

    return { executionDocumentsSsp };
  },
  mounted() {
    this.itemInternal = JSON.parse(JSON.stringify(this.item));
    this.initValues();
  },
  computed: {
    ...mapGetters({
      debtorData: "debtorCardInfo/debtorData",
      debtsData: "debtorCardDebtsAndPaymentsData/debtsData",
    }),

    isActualDebtMore() {
      return this.debtsData.actualDebtsGrouped.debtsAll > this.deliveredSum;
    },

    bailiffId: {
      get() {
        return this.debtorData.house ? this.debtorData.house.bailiffId : 0;
      },
    },
    canGenerateDoc: {
      get() {
        return this.templateId != null;
      },
    },
    id() {
      if (this.item) return this.item.current.id;
      return 0;
    },

    documentId() {
      var rows = this.executionDocumentsSsp.filter((r) => r.inhabitantId == this.item.inhabitantId && r.current.id == this.id);
      if (rows && rows.length != 0) {
        return rows[0].current.documentId;
      }
      return null;
    },
    doc() {
      var rows = this.executionDocumentsSsp.filter((r) => r.inhabitantId == this.item.inhabitantId && r.current.id == this.id);
      if (rows && rows.length != 0 && rows[0].current.document) {
        return rows[0].current.document;
      }
      return null;
    },
  },
  data() {
    return {
      sspStatusesStore,
      solidarity: [
        { text: "Солидарно", value: true },
        { text: "По долям", value: false },
      ],
      itemInternal: this.item,
      sspStatusId: 5,

      name: "",
      isExistAgreement: false,
      executionDocNo: "",
      inhabitantId: 0,
      sendDate: new Date(),
      notes: "",
      isSolidarity: true,
      deliveredSum: 0,
      // deliveredSumIsSolidarity: true,
      stateFee: 0,
      // stateFeeIsSolidarity: true,
      penalties: 0,
      // penaltiesIsSolidarity: true,
      templateId: null,
      bailiff: null,
      executionDocNumber: null,
      bailiffName: null,
      enforcementStartDate: null,
      enforcementFinishDate: null,
      templatesStore: createStoreExtend({
        key: "fileId",
        loadUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_BAILIFFS_GET_TEMPLATES}`,
        onBeforeSend: (_method, ajaxOptions) => {
          ajaxOptions.data.bailiffId = this.bailiffId;
        },
      }),
    };
  },
  methods: {
    initValues() {
      this.name = this.itemInternal.name;
      this.isExistAgreement = this.itemInternal.isExistAgreement;
      this.executionDocNo = this.itemInternal.executionDocNo;
      this.inhabitantId = this.itemInternal.inhabitantId;
      if (this.item.current.data) {
        const data = this.item.current.data;
        if (data.sspStatusId) {
          this.sspStatusId = this.item.current.data.sspStatusId;
        } else {
          this.sspStatusId = 5;
        }

        if (data.sspExecutionDocNumber) this.executionDocNumber = data.sspExecutionDocNumber;
        if (data.sspEnforcementStartDate) this.enforcementStartDate = data.sspEnforcementStartDate;
        if (data.sspEnforcementFinishDate) this.enforcementFinishDate = data.sspEnforcementFinishDate;
        if (data.sspBailiffName) this.bailiffName = data.sspBailiffName;
        if (data.sspBailiff) this.bailiff = data.sspBailiff;
      }

      console.log(this.isEditing);
      if (this.isEditing) {
        this.sendDate = this.item.current.sendDate;
      } else {
        this.sendDate = new Date();
      }

      this.notes = this.item.current.notes;
      this.deliveredSum = this.item.current.deliveredSum;

      if (this.item.current.deliveredSumIsSolidarity != undefined) {
        // this.deliveredSumIsSolidarity = this.item.current.deliveredSumIsSolidarity;
        this.isSolidarity = this.item.current.deliveredSumIsSolidarity;
      }

      this.stateFee = this.item.current.stateFee;

      // if (this.item.current.stateFeeIsSolidarity != undefined) {
      //   this.stateFeeIsSolidarity = this.item.current.stateFeeIsSolidarity;
      // }

      this.penalties = this.item.current.penalties;

      // if (this.item.current.penaltiesIsSolidarity != undefined) {
      //   this.penaltiesIsSolidarity = this.item.current.penaltiesIsSolidarity;
      // }
      this.templateId = this.item.current.templateId;

      if (this.additionalInfo) {
        if (this.additionalInfo.bailiff) {
          this.bailiff = this.additionalInfo.bailiff;
          blink(this, 3, "tbBailiff");
        }
      }
    },

    f(o) {
      return formatNumber(o, { type: "fixedPoint", precision: 2 });
    },
    fdt(date) {
      return formatDate(new Date(date), "shortDateShortTime");
    },

    copyAdditionalInfo() {
      if (this.additionalInfo) {
        blink(this, 3, "tbDeliveredSum");
        blink(this, 3, "tbStateFee");
        blink(this, 3, "tbPenalties");
        if (this.additionalInfo.courtDecisionSum) {
          this.deliveredSum = this.additionalInfo.courtDecisionSum;
        }

        if (this.additionalInfo.courtDecisionStateFee) {
          this.stateFee = this.additionalInfo.courtDecisionStateFee;
        }
        if (this.additionalInfo.courtDecisionPenalties) {
          this.penalties = this.additionalInfo.courtDecisionPenalties;
        }
      }
    },
    generateDocument(e) {
      let result = e.validationGroup.validate();
      if (!result.isValid) {
        return;
      }

      var selectedItem = this.$refs.cmbSsp.instance.option("selectedItem");

      var item = {
        inhabitantId: this.inhabitantId,
        name: this.name,
        executionDocNo: this.executionDocNo,
        current: {
          id: this.id,
          sendDate: this.sendDate,
          data: {
            sspStatusId: this.sspStatusId,
            sspStatus: selectedItem.name,
            dataType: 4,
            sspBailiff: this.bailiff,
            sspExecutionDocNumber: this.executionDocNumber,
            sspBailiffName: this.bailiffName,
            sspEnforcementStartDate: this.enforcementStartDate,
            sspEnforcementFinishDate: this.enforcementFinishDate,
          },
          notes: this.notes,
          deliveredSum: this.deliveredSum,
          // deliveredSumIsSolidarity: this.deliveredSumIsSolidarity,
          deliveredSumIsSolidarity: this.isSolidarity,
          stateFee: this.stateFee,
          // stateFeeIsSolidarity: this.stateFeeIsSolidarity,
          stateFeeIsSolidarity: this.isSolidarity,
          penalties: this.penalties,
          // penaltiesIsSolidarity: this.penaltiesIsSolidarity,
          penaltiesIsSolidarity: this.isSolidarity,
          templateId: this.templateId,
          documentId: this.documentId,
        },
      };

      this.$emit("generate", item);
    },
    downloadDocument() {
      var item = {
        inhabitantId: this.inhabitantId,
        current: {
          id: this.id,
          templateId: this.templateId,
          documentId: this.documentId,
        },
      };

      this.$emit("download", item);
    },
    cancel() {
      this.$emit("cancel", this.itemInternal);
    },
    save(e) {
      let result = e.validationGroup.validate();
      if (!result.isValid) {
        return;
      }

      var selectedItem = this.$refs.cmbSsp.instance.option("selectedItem");

      var item = {
        inhabitantId: this.inhabitantId,
        name: this.name,
        executionDocNo: this.executionDocNo,
        current: {
          id: this.id,
          sendDate: this.sendDate,
          data: {
            sspStatusId: this.sspStatusId,
            sspStatus: selectedItem.name,
            dataType: 4,
            sspBailiff: this.bailiff,
            sspExecutionDocNumber: this.executionDocNumber,
            sspBailiffName: this.bailiffName,
            sspEnforcementStartDate: this.enforcementStartDate,
            sspEnforcementFinishDate: this.enforcementFinishDate,
          },
          notes: this.notes,
          deliveredSum: this.deliveredSum,
          // deliveredSumIsSolidarity: this.deliveredSumIsSolidarity,
          deliveredSumIsSolidarity: this.isSolidarity,
          stateFee: this.stateFee,
          // stateFeeIsSolidarity: this.stateFeeIsSolidarity,
          stateFeeIsSolidarity: this.isSolidarity,
          penalties: this.penalties,
          // penaltiesIsSolidarity: this.penaltiesIsSolidarity,
          penaltiesIsSolidarity: this.isSolidarity,
          templateId: this.templateId,
          documentId: this.documentId,
        },
      };

      this.$emit("save", item);
    },
  },
};
</script>
