import axios from "axios";
import { defineStore } from "pinia";
import notify from "devextreme/ui/notify";

const moveNextUrl = `${process.env.VUE_APP_URL}/api/writs/ex-doc-move`;
const updateUrl = `${process.env.VUE_APP_URL}/api/writs/ex-doc-update`;
const deleteExecutionMoveUrl = `${process.env.VUE_APP_URL}/api/writs/ex-doc-move-delete`;
const getUrl = `${process.env.VUE_APP_URL}/api/writs/ex-docs/`;
const getMovesUrl = `${process.env.VUE_APP_URL}/api/writs/ex-doc-moves/`;

export const useWritCardExecutionStore = defineStore({
  id: "writCardExecution",
  state: () => ({
    isLoading: false,
    error: null,

    writId: null,
    executionDocuments: [],
  }),
  getters: {
    executionDocumentsOffice(state) {
      return state.executionDocuments.filter((r) => r.current.data.dataType == 1);
    },
    executionDocumentsBank(state) {
      return state.executionDocuments.filter((r) => r.current.data.dataType == 2);
    },

    executionDocumentsPfr(state) {
      return state.executionDocuments.filter((r) => r.current.data.dataType == 3);
    },

    executionDocumentsSsp(state) {
      return state.executionDocuments.filter((r) => r.current.data.dataType == 4);
    },
  },
  actions: {
    resetError() {
      this.error = null;
    },

    async load(writId) {
      this.resetError();
      this.$reset();
      this.loading = true;
      try {
        var response = await axios.get(getUrl + writId);
        var data = response.data;
        this.writId = writId;
        this.executionDocuments = data;
      } catch (error) {
        this.error = error;
        throw error;
      }
    },
    async loadDocumentMoves(inhabitantId) {
      this.resetError();
      this.loading = true;
      try {
        var response = await axios.get(getMovesUrl + `${this.writId}/${inhabitantId}`);
        var data = response.data;
        const doc = this.executionDocuments.find((r) => r.inhabitantId == inhabitantId)
        if (doc) {
          doc.moves = data.moves;
        }
        else {
          this.executionDocuments.push(data);
        }
      } catch (error) {
        this.error = error;
        throw error;
      }
    },

    async moveNext(item) {
      this.resetError();
      this.loading = true;
      try {
        const response = await axios.post(moveNextUrl, {
          writId: this.writId,
          executionDocument: item
        });
        var data = response.data;
        var ind = this.executionDocuments.findIndex(r => r.id == item.id);
        this.executionDocuments[ind] = item;
        this.executionDocuments[ind].id = data;
      } catch (error) {
        this.error = error;
        throw error;
      }
    },

    async deleteMove(item) {
      this.resetError();
      this.loading = true;
      try {
        await axios.delete(deleteExecutionMoveUrl + "/" + item.current.id);
        await this.load(this.writId);
      } catch (error) {
        this.error = error;
        throw error;
      }
    },

    async update(item) {
      this.resetError();
      this.loading = true;
      try {
        const response = await axios.post(updateUrl, {
          writId: this.writId,
          executionDocument: item
        });
        var data = response.data;
        var ind = this.executionDocuments.findIndex(r => r.inhabitantId == item.inhabitantId);
        this.executionDocuments[ind] = item;
        this.executionDocuments[ind].current.id = data;
      } catch (error) {
        this.error = error;
        throw error;
      }
    },


    async generateExecutionDocument(inhabitantId, moveId) {
      const response = await axios.post(`${process.env.VUE_APP_URL}/api/writs/generate-execution-document/${this.writId}/${inhabitantId}/${moveId}`, {
        id: this.writId,
        inhabitantId: inhabitantId,
        moveId: moveId
      });

      notify({
        message: "Документ сформирован",
        height: 40,
        width: 190,
        minWidth: 190,
        position: {
          my: "bottom left",
          at: "bottom left",
          offset: '20 -20'
        },
        displayTime: 3500
      }, "success");


      const move = this.executionDocuments.filter((r) => r.inhabitantId == inhabitantId && r.current.id == moveId)[0];
      move.current.documentId = response.data.id;
      move.current.document = response.data;
      return response.data.id;
    },

    async downloadExecutionDocument(inhabitantId, moveId, fileName) {
      axios
        .get(`${process.env.VUE_APP_URL}/api/Writs/execution-document/${this.writId}/${inhabitantId}/${moveId}`, { responseType: "arraybuffer" })
        .then(function(response) {
          var headers = response.headers;
          var blob = new Blob([response.data], { type: headers["content-type"] });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          fileName = `${fileName}`.replaceAll("\\", "").replaceAll("/", "");
          link.download = fileName;
          link.click();
        });
    }
  },
});
