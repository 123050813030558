<template>
  <div>
    <div class="d-flex">
      <div class="flex-1">
        <MovedOffice v-if="itemInternal.current.data.dataType == states.Office.id" :item="itemInternal" />
        <MovedBank v-else-if="itemInternal.current.data.dataType == states.Bank.id" :item="itemInternal" @download="download" />
        <MovedSsp v-else-if="itemInternal.current.data.dataType == states.Ssp.id" :item="itemInternal" @download="download" />
        <MovedPfr v-else-if="itemInternal.current.data.dataType == states.Pfr.id" :item="itemInternal" @download="download" />
      </div>
      <div>
        <div class="flex-shrink-0 d-flex flex-column align-items-end">
          <h5 class="text-success fs-14 mb-0">
            {{ fd(itemInternal.current.sendDate) }}
          </h5>
          <button
            class="btn btn-danger btn-icon waves-effect waves-light mt-1"
            type="button"
            v-b-tooltip.hover
            title="Отменить перемещение"
            v-if="canDelete"
            @click="deleteMove(item)"
          >
            <Trash2Icon class="i" />
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="d-flex col-md-12 align-items-end justify-content-end">
        <button
          class="btn btn-success btn-icon me-2 waves-effect waves-light"
          type="button"
          v-b-tooltip.hover
          title="История перемещений"
          @click="showHistory"
        >
          <i class="fs-18 bx bx-history"></i>
        </button>
        <button
          class="btn btn-success btn-icon waves-effect waves-light"
          type="button"
          v-b-tooltip.hover
          title="Редактировать"
          @click="edit"
        >
          <i class="fs-18 bx bx-edit-alt"></i>
        </button>
      </div>
    </div>

    <DxPopupModal
      :visible="showPopupHistory"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-close-button="false"
      :width="700"
      title="История перемещений"
    >
      <DxPopupPosition at="center" my="center" />
      <DxScrollView width="100%" height="100%">
        <div class="h-100 px-4">
          <div class="row mt-2">
            <div class="d-flex gap-3">
              <button class="btn btn-danger btn-sm ms-auto" @click="showPopupHistory = false">Закрыть</button>
            </div>
          </div>
          <MovedHistory :item="itemInternal" @download="download" @delete="deleteMove" />
        </div>
      </DxScrollView>
    </DxPopupModal>

    <DxPopupModal
      :visible="showPopup"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-close-button="false"
      :show-title="false"
      :width="700"
      height="auto"
    >
      <DxPopupPosition at="center" my="center" />
      <div class="h-100">
        <EditOffice
          v-if="itemInternal.current.data.dataType == states.Office.id"
          :item="itemInternal"
          @cancel="cancel"
          @save="save"
          :key="key"
          is-editing
        />
        <EditBank
          v-else-if="itemInternal.current.data.dataType == states.Bank.id"
          :item="itemInternal"
          @cancel="cancel"
          @save="save"
          :key="key / 2"
          @generate="generate"
          @download="download"
          is-editing
        />
        <EditSsp
          v-else-if="itemInternal.current.data.dataType == states.Ssp.id"
          :item="itemInternal"
          :additionalInfo="additionalInfo"
          :key="key / 3"
          @save="save"
          @cancel="cancel"
          @generate="generate"
          @download="download"
          is-editing
        />
        <EditPfr
          v-else-if="itemInternal.current.data.dataType == states.Pfr.id"
          :item="itemInternal"
          :key="key / 4"
          @cancel="cancel"
          @save="save"
          @generate="generate"
          @download="download"
          is-editing
        />
      </div>
    </DxPopupModal>
  </div>
</template>

<script>
import { inject } from "vue";
import { useWritCardExecutionStore } from "@/state/writ.card.execution";
import { storeToRefs } from "pinia";
import { formatDate } from "devextreme/localization";

import { Trash2Icon } from "@zhuowenli/vue-feather-icons";
import { DxScrollView } from "devextreme-vue/scroll-view";

import MovedHistory from "./MovedHistory.vue";
import MovedOffice from "./MovedItemOffice";
import MovedBank from "./MovedItemBank";
import MovedSsp from "./MovedItemSsp";
import MovedPfr from "./MovedItemPfr";

import EditOffice from "./EditItemOffice";
import EditBank from "./EditItemBank";
import EditSsp from "./EditItemSsp";
import EditPfr from "./EditItemPfr";

export default {
  components: {
    MovedHistory,
    MovedOffice,
    EditOffice,
    MovedBank,
    EditBank,
    MovedSsp,
    EditSsp,
    MovedPfr,
    EditPfr,
    DxScrollView,
    Trash2Icon,
  },
  props: {
    item: {
      type: Object,
    },
    additionalInfo: {
      type: Object,
    },
  },
  emits: ["edit", "change", "generate", "download", "delete"],
  setup() {
    var states = inject("states");

    const storeMoves = useWritCardExecutionStore();
    const { executionDocMoves } = storeToRefs(storeMoves);

    return {
      states,
      executionDocMoves,
    };
  },
  watch: {
    item() {
      this.itemInternal = JSON.parse(JSON.stringify(this.item));
    },
  },
  computed: {
    canDelete() {
      return this.item && this.item.moves && this.item.moves.length > 1;
    },
  },
  data() {
    return {
      key: 1,
      showPopup: false,
      showPopupHistory: false,
      itemInternal: this.item,
    };
  },
  methods: {
    fd(date) {
      return formatDate(new Date(date), "shortDate");
    },
    showHistory() {
      this.showPopupHistory = true;
    },
    edit() {
      this.key++;
      this.showPopup = true;
    },
    cancel() {
      this.showPopup = false;
    },
    generate(item) {
      this.$emit("change", item);
      this.$emit("generate", item);
      this.showPopup = true;
    },
    download(item) {
      this.$emit("download", item);
    },
    save(item) {
      this.$emit("change", item);
      this.showPopup = false;
    },
    deleteMove(item) {
      this.$emit("delete", item);
    },
  },
};
</script>

<style></style>
