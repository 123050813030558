<template>
  <div class="h-100">
    <DxValidationGroup>
      <div class="row">
        <div class="col-lg-12">
          <strong>Перемещение в Банк. {{ name }}</strong>
          <strong class="ps-1 text-danger" v-if="isExistAgreement">ВНИМАНИЕ! Действует соглашение</strong>
          <hr class="my-1" />
        </div>
      </div>
      <DxValidationSummary />

      <div class="row mt-3">
        <div class="col-md-6">
          <div class="d-flex align-items-center">
            <DxButton class="w-100" type="default" text="Скопировать данные из решения" @click="copyAdditionalInfo" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="d-flex align-items-center">
            <DxRadioGroup layout="horizontal" display-expr="text" value-expr="value" :data-source="solidarity" v-model="isSolidarity" />
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-6">
          <label class="form-label mb-0 me-2 text-nowrap">Дата передачи:</label>
          <DxDateBox v-model="sendDate" :show-clear-button="true" :use-mask-behavior="true" placeholder="введите или выберите" />
        </div>
        <div class="col-md-6">
          <label class="form-label mb-0 me-2 text-nowrap">Номер исполнительного листа:</label>
          <DxTextBox v-model="executionDocNo" :show-clear-button="true" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <label class="form-label mb-0 me-2 text-nowrap">Переданная сумма:</label>
          <DxNumberBox v-model="deliveredSum" :show-clear-button="true" ref="tbDeliveredSum" format="#,##0.00" />
        </div>
        <div class="col-md-6">
          <div class="mb-0 alert" :class="{ 'alert-primary': isActualDebtMore, 'alert-danger': !isActualDebtMore }" ref="lblActualDebts">
            Долг на {{ debtsData.actualDebtsGrouped.actualDate }}: {{ f(debtsData.actualDebtsGrouped.debtsAll) }} руб.
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <label class="form-label mb-0 me-2 text-nowrap">Пени:</label>
          <DxNumberBox v-model="penalties" :show-clear-button="true" ref="tbPenalties" format="#,##0.00" />
        </div>
        <div class="col-md-6">
          <label class="form-label mb-0 me-2 text-nowrap">Гос. пошлина:</label>
          <DxNumberBox v-model="stateFee" :show-clear-button="true" ref="tbStateFee" format="#,##0.00" />
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <div class="d-flex align-items-center">
            <label class="form-label mb-0 me-2">Банк:</label>
            <DxTextBox class="w-100" v-model="bankName">
              <DxValidator>
                <DxRequiredRule :message="'Поле Банк обязательное'" />
              </DxValidator>
            </DxTextBox>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <label class="form-label mb-0 me-2">Примечание:</label>
          <textarea type="text" class="form-control form-control-sm" v-model="notes" rows="3" />
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-12">
          <div class="d-flex align-items-center">
            <label class="form-label mb-0 me-2">Шаблон:</label>
            <DxSelectBox
              ref="cmbTemplates"
              display-expr="name"
              value-expr="fileId"
              v-model="templateId"
              :data-source="templatesStore"
              :show-clear-button="true"
              class="w-100"
            ></DxSelectBox>
          </div>
        </div>
      </div>

      <div class="row mt-2" v-if="documentId != null">
        <div class="col-md-12">
          <DxButton class="btn-label b primary p-2" @click="downloadDocument">
            <div class="d-flex">
              <span class="label-icon">
                <DownloadIcon class="i" />
              </span>
              <span class="ms-4"> Скачать заявление </span>
            </div>
          </DxButton>
          <span class="align-middle text-success"> сформировано {{ fdt(doc.created) }} </span>
        </div>
      </div>

      <div class="form-row row mt-2" v-if="canGenerateDoc">
        <div class="form-group col-md-8">
          <DxButton class="btn-label b primary p-2" @click="generateDocument">
            <div class="d-flex">
              <span class="label-icon">
                <FileTextIcon class="i" />
              </span>
              <span class="ms-4">Сформировать печатную форму заявления </span>
            </div>
          </DxButton>
        </div>
      </div>

      <div class="row mt-3">
        <div class="d-flex gap-3">
          <button class="btn btn-danger btn-sm ms-auto" @click="cancel">Отменить</button>
          <DxButton class="btn-success" id="btnSaveBank" text="Сохранить" @click="save" />
        </div>
      </div>
    </DxValidationGroup>
  </div>
</template>

<script>
import DxValidationSummary from "devextreme-vue/validation-summary";
import DxValidationGroup from "devextreme-vue/validation-group";
import DxRadioGroup from "devextreme-vue/radio-group";
import { FileTextIcon, DownloadIcon } from "@zhuowenli/vue-feather-icons";
import { mapGetters } from "vuex";
import { blink } from "@/helpers";
import { formatNumber } from "devextreme/localization";
import { useWritCardExecutionStore } from "../../../state/writ.card.execution";
import { storeToRefs } from "pinia";
import { formatDate } from "devextreme/localization";
import createStoreExtend from "@/helpers/grid/store";

export default {
  components: {
    DxRadioGroup,
    DxValidationSummary,
    DxValidationGroup,
    FileTextIcon,
    DownloadIcon,
  },
  props: {
    item: {
      type: Object,
    },
    additionalInfo: {
      type: Object,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["cancel", "save", "generate", "download"],
  setup() {
    const store = useWritCardExecutionStore();
    const { executionDocumentsBank } = storeToRefs(store);

    return { executionDocumentsBank };
  },
  mounted() {
    this.itemInternal = JSON.parse(JSON.stringify(this.item));
    this.initValues();
  },
  computed: {
    ...mapGetters({
      debtorData: "debtorCardInfo/debtorData",
      debtsData: "debtorCardDebtsAndPaymentsData/debtsData",
    }),

    isActualDebtMore() {
      return this.debtsData.actualDebtsGrouped.debtsAll > this.deliveredSum;
    },

    canGenerateDoc: {
      get() {
        return this.templateId != null;
      },
    },
    documentId() {
      var rows = this.executionDocumentsBank.filter((r) => r.inhabitantId == this.item.inhabitantId && r.current.id == this.id);
      if (rows && rows.length != 0) {
        return rows[0].current.documentId;
      }
      return null;
    },
    doc() {
      var rows = this.executionDocumentsBank.filter((r) => r.inhabitantId == this.item.inhabitantId && r.current.id == this.id);
      if (rows && rows.length != 0 && rows[0].current.document) {
        return rows[0].current.document;
      }
      return null;
    },
  },
  data() {
    return {
      solidarity: [
        { text: "Солидарно", value: true },
        { text: "По долям", value: false },
      ],
      templatesStore: createStoreExtend({
        key: "fileId",
        loadUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_TEMPLATES_GET}?templateGroupId=3`,
      }),
      itemInternal: this.item,
      bankName: "",
      templateId: null,

      id: 0,
      inhabitantId: 0,
      name: "",
      isExistAgreement: false,
      executionDocNo: "",
      sendDate: new Date(),
      notes: "",
      isSolidarity: true,
      deliveredSum: 0,
      stateFee: 0,
      penalties: 0,
    };
  },
  methods: {
    f(o) {
      return formatNumber(o, { type: "fixedPoint", precision: 2 });
    },
    fdt(date) {
      return formatDate(new Date(date), "shortDateShortTime");
    },
    copyAdditionalInfo() {
      if (this.additionalInfo) {
        blink(this, 3, "tbDeliveredSum");
        blink(this, 3, "tbStateFee");
        blink(this, 3, "tbPenalties");
        if (this.additionalInfo.courtDecisionSum) {
          this.deliveredSum = this.additionalInfo.courtDecisionSum;
        }

        if (this.additionalInfo.courtDecisionStateFee) {
          this.stateFee = this.additionalInfo.courtDecisionStateFee;
        }
        if (this.additionalInfo.courtDecisionPenalties) {
          this.penalties = this.additionalInfo.courtDecisionPenalties;
        }
      }
    },
    initValues() {
      this.id = this.itemInternal.current.id;
      this.inhabitantId = this.itemInternal.inhabitantId;
      this.name = this.itemInternal.name;
      this.isExistAgreement = this.itemInternal.isExistAgreement;
      this.executionDocNo = this.itemInternal.executionDocNo;
      if (this.itemInternal.current.data) this.bankName = this.itemInternal.current.data.bankName;
      if (this.isEditing) {
        this.sendDate = this.item.current.sendDate;
      } else {
        this.sendDate = new Date();
      }
      this.notes = this.itemInternal.current.notes;
      this.deliveredSum = this.item.current.deliveredSum;
      if (this.item.current.deliveredSumIsSolidarity != undefined) {
        this.isSolidarity = this.item.current.deliveredSumIsSolidarity;
      }
      this.stateFee = this.item.current.stateFee;
      this.penalties = this.item.current.penalties;
      this.templateId = this.item.current.templateId;
    },
    cancel() {
      this.$emit("cancel", this.itemInternal);
    },
    save(e) {
      let result = e.validationGroup.validate();
      if (!result.isValid) {
        return;
      }

      var item = {
        inhabitantId: this.inhabitantId,
        name: this.name,
        executionDocNo: this.executionDocNo,
        current: {
          id: this.id,
          data: { bankName: this.bankName, dataType: 2 },
          sendDate: this.sendDate,
          notes: this.notes,
          deliveredSum: this.deliveredSum,
          deliveredSumIsSolidarity: this.isSolidarity,
          stateFee: this.stateFee,
          stateFeeIsSolidarity: this.isSolidarity,
          penalties: this.penalties,
          penaltiesIsSolidarity: this.isSolidarity,
          templateId: this.templateId,
          documentId: this.documentId,
        },
      };

      this.$emit("save", item);
    },
    generateDocument(e) {
      let result = e.validationGroup.validate();
      if (!result.isValid) {
        return;
      }

      var item = {
        inhabitantId: this.inhabitantId,
        name: this.name,
        executionDocNo: this.executionDocNo,
        current: {
          id: this.id,
          data: { bankName: this.bankName, dataType: 2 },
          sendDate: this.sendDate,
          notes: this.notes,
          deliveredSum: this.deliveredSum,
          deliveredSumIsSolidarity: this.isSolidarity,
          stateFee: this.stateFee,
          stateFeeIsSolidarity: this.isSolidarity,
          penalties: this.penalties,
          penaltiesIsSolidarity: this.isSolidarity,
          templateId: this.templateId,
          documentId: this.documentId,
        },
      };
      this.$emit("generate", item);
    },
    downloadDocument() {
      var item = {
        inhabitantId: this.inhabitantId,
        current: {
          id: this.id,
          templateId: this.templateId,
          documentId: this.documentId,
        },
      };

      this.$emit("download", item);
    },
  },
};
</script>

<style></style>
