<template>
  <div class="h-100">
    <div class="row">
      <div class="col-lg-12">
        <strong>Перемещение в Офис. {{ name }}</strong>
        <strong class="ps-1 text-danger" v-if="isExistAgreement">ВНИМАНИЕ! Действует соглашение</strong>
        <hr class="my-1" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-6">
        <label class="form-label mb-0 me-2 text-nowrap">Дата передачи:</label>
        <DxDateBox v-model="sendDate" :show-clear-button="true" :use-mask-behavior="true" placeholder="16.10.2018" />
      </div>
      <div class="col-md-6">
        <label class="form-label mb-0 me-2 text-nowrap">Номер исполнительного листа:</label>
        <DxTextBox v-model="executionDocNo" :show-clear-button="true" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <label class="form-label mb-0 me-2">Примечание:</label>
        <textarea type="text" class="form-control form-control-sm" v-model="notes" rows="3" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="d-flex gap-3">
        <button class="btn btn-danger btn-sm ms-auto" @click="cancel">Отменить</button>
        <button class="btn btn-success btn-sm" @click="save">Сохранить</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  emits: ["cancel", "save"],
  mounted() {
    this.itemInternal = JSON.parse(JSON.stringify(this.item));
    this.initValues();
  },
  data() {
    return {
      itemInternal: this.item,
      id: 0,
      inhabitantId: 0,
      name: "",
      isExistAgreement: false,
      executionDocNo: "",
      sendDate: new Date(),
      notes: "",
      deliveredSum: 0,
      deliveredSumIsSolidarity: true,
      stateFee: 0,
      stateFeeIsSolidarity: true,
    };
  },
  methods: {
    initValues() {
      this.id = this.itemInternal.current.id;
      this.name = this.itemInternal.name;
      this.isExistAgreement = this.itemInternal.isExistAgreement;
      this.executionDocNo = this.itemInternal.executionDocNo;
      this.inhabitantId = this.itemInternal.inhabitantId;
      if(this.isEditing) {
        this.sendDate = this.item.current.sendDate;
      } else {
        this.sendDate = new Date();
      }
      this.notes = this.itemInternal.current.notes;
      this.deliveredSum = this.itemInternal.current.deliveredSum;
      this.deliveredSumIsSolidarity = this.itemInternal.current.deliveredSumIsSolidarity;
      this.stateFee = this.itemInternal.current.stateFee;
      this.stateFeeIsSolidarity = this.itemInternal.current.stateFeeIsSolidarity;
    },
    cancel() {
      this.$emit("cancel", this.itemInternal);
    },
    save() {
      var item = {
        inhabitantId: this.inhabitantId,
        name: this.itemInternal.name,
        executionDocNo: this.executionDocNo,
        current: {
          id: this.id,
          sendDate: this.sendDate,
          data: { dataType: 1 },
          notes: this.notes,
          deliveredSum: 0,
          deliveredSumIsSolidarity: this.deliveredSumIsSolidarity,
          stateFee: 0,
          stateFeeIsSolidarity: this.stateFeeIsSolidarity,
        },
      };

      this.$emit("save", item);
    },
  },
};
</script>

<style></style>
