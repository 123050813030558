<template>
  <div v-if="item && item.moves">
    <div v-for="(move, index) in item.moves" :key="index">
      <div class="d-flex">
        <div class="flex-1">
          <div v-if="move.data.dataType == states.Office.id">
            <b> Офис </b>
            <MovedOffice :item="{ name: item.name, current: move }" />
          </div>
          <div v-else-if="move.data.dataType == states.Bank.id">
            <b> Банк </b>
            <MovedBank :item="{ name: item.name, current: move }" />
          </div>
          <div v-else-if="move.data.dataType == states.Ssp.id">
            <b> ССП </b>
            <MovedSsp :item="{ name: item.name, current: move }" @download="download({ name: item.name, current: move })" />
          </div>
          <div v-else-if="move.data.dataType == states.Pfr.id">
            <b> ПФР </b>
            <MovedPfr :item="{ name: item.name, current: move }" />
          </div>
        </div>
        <div>
          <div class="flex-shrink-0 d-flex flex-column align-items-end">
            <h5 class="text-success fs-14 mb-0">
              {{ fd(move.sendDate) }}
            </h5>
            <button
              class="btn btn-danger btn-icon waves-effect waves-light mt-1"
              tabindex="0"
              type="button"
              v-b-tooltip.hover
              title="Отменить перемещение"
              v-if="canDelete"
              @click="deleteMove({ name: item.name, current: move })"
            >
              <Trash2Icon class="i" />
            </button>
          </div>
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import { useWritCardExecutionStore } from "@/state/writ.card.execution";
import { formatDate } from "devextreme/localization";
import { Trash2Icon } from "@zhuowenli/vue-feather-icons";

import MovedOffice from "./MovedItemOffice";
import MovedBank from "./MovedItemBank";
import MovedSsp from "./MovedItemSsp";
import MovedPfr from "./MovedItemPfr";

export default {
  components: {
    MovedOffice,
    MovedBank,
    MovedSsp,
    MovedPfr,
    Trash2Icon
  },
  props: {
    item: {
      type: Object,
    },
  },
  emits: ["download", "delete"],
  setup() {
    var states = inject("states");

    const store = useWritCardExecutionStore();

    return {
      states,
      store,
    };
  },
  computed: {
    executionDocMoves() {
      return this.item.moves;
    },
    canDelete() {
      return this.item && this.item.moves && this.item.moves.length > 1;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    fd(date) {
      return formatDate(new Date(date), "shortDate");
    },
    async load() {
      this.store.loadDocumentMoves(this.item.inhabitantId);
    },
    download(item) {
      this.$emit("download", item);
    },
    deleteMove(item) {
      this.$emit("delete", item);
    }
  },
};
</script>

<style>
.tooltip {
  z-index: 999999;
}
</style>
