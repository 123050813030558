<template>
  <div>
    <div class="alert alert-primary alert-border-left alert-dismissible fade show" role="alert">
      <strong>6. Файлы</strong>
    </div>
    <div class="row">
      <div class="col-xl-12 d-flex align-items-center">
        <DxFileUploader
          name="file"
          :multiple="false"
          :chunk-size="200000"
          :upload-url="fileUploadUrl"
          :uploadHeaders="headers"
          @upload-started="() => (chunks = [])"
          @uploaded="filesUploaded"
          :max-file-size="209715200"
        />
      </div>
      <div class="col-xl-12 d-flex align-items-center">
        <table class="table">
          <tbody>
            <tr v-for="(item, index) in files" :key="index">
              <td style="width: 80px"><i class="bx bxs-file fs-24 text-muted"></i></td>
              <td>{{ item.fileName }}</td>
              <td>
                <a href="#" v-if="item.id != 0" @click.prevent="download(item)">Скачать</a>
              </td>
              <td class="justify-content-end text-end"><a href="#" @click.prevent="deleteFile(item, index)">Удалить</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
const fileUploadUrl = process.env.VUE_APP_URL + "/api/Files/UploadChunk";
const fileDownloadUrl = process.env.VUE_APP_URL + "/api/Files/";
const fileDeleteUrl = process.env.VUE_APP_URL + "/api/Files/";

import axios from "axios";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import { authHeader } from "@/helpers/authservice/auth-header";
import { confirm } from "devextreme/ui/dialog";
import { useWritCardStore } from "../../state/writ.card";
import { storeToRefs } from "pinia";

export default {
  components: {
    DxFileUploader,
  },
  setup() {
    const store = useWritCardStore();
    const { writ } = storeToRefs(store);
    return { store, writ };
  },
  mounted() {},
  computed: {
    files() {
      return this.writ.files;
    },
  },
  data() {
    return {
      fileUploadUrl,
      fileDownloadUrl,
      fileDeleteUrl,
      headers: authHeader(),
    };
  },
  methods: {
    filesUploaded(e) {
      var obj = JSON.parse(e.request.responseText);
      this.files.push({ id: 0, tempFileName: obj.tempFileName, fileName: e.file.name });
      e.component.reset();
    },
    download(item) {
      axios.get(fileDownloadUrl + item.id, { responseType: "arraybuffer" }).then(function (response) {
        var headers = response.headers;
        var blob = new Blob([response.data], { type: headers["content-type"] });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = item.fileName;
        link.click();
      });
    },
    deleteFile(item, index) {
      let result = confirm(
        "<i class='dx-icon ri-delete-bin-5-line align-middle fs-22 me-2'></i><span class='fs-5 align-middle'>Вы уверены?</span>",
        "Удаление файлов"
      );
      result.then((dialogResult) => {
        if (dialogResult) {
          if (item.id != 0) {
            axios.post(fileDownloadUrl + item.id, { id: item.id }).then(() => {
              this.files.splice(index, 1);
            });
          } else {
            this.files.splice(index, 1);
          }
        }
      });
    },
  },
};
</script>

<style></style>
