<template>
  <Layout>
    <PageHeader :title="title" :items="items" v-if="title">
      <div class="d-flex gap-2">
        <DebtSummaryText :actualDate="debtsData.actualDebtsGrouped.actualDate" :actualDebts="f(debtsData.actualDebtsGrouped.debtsAll)" />
        <b-button variant="success" @click="startDownloadSummaryReport">
          <span class="align-middle">Справка</span>
        </b-button>
      </div>
    </PageHeader>

    <DxLoadPanel :position="{ of: '#mainCard' }" :visible="isLoading" shading-color="rgba(0,0,0,0.4)" />
    <div class="row">
      <div class="col-12">
        <transition>
          <b-alert show variant="danger" v-if="errors && errors.length > 0">
            <ul class="m-0">
              <li v-for="(item, index) in errors" :key="index">
                <strong>{{ item }}</strong>
              </li>
            </ul>
          </b-alert>
        </transition>

        <ul class="row arrow-steps nav m-0">
          <li
            class="col step ms-0"
            :class="{
              selected: selectedState == writStates.preparation,
              done: currentWritState >= writStates.preparation,
            }"
            @click="selectState(writStates.preparation)"
          >
            <div>
              <CheckIcon v-if="currentWritState >= writStates.preparation" />
              <span> Подготовка </span>
            </div>
          </li>
          <li
            class="col step"
            :class="{
              selected: selectedState == writStates.filing,
              done: currentWritState >= writStates.filing,
            }"
            @click="selectState(writStates.filing)"
          >
            <div>
              <CheckIcon v-if="currentWritState >= writStates.filing" />
              <span>Подача</span>
            </div>
          </li>
          <li
            class="col step"
            :class="{
              selected: selectedState == writStates.decision,
              done: currentWritState >= writStates.decision,
            }"
            @click="selectState(writStates.decision)"
          >
            <div>
              <CheckIcon v-if="currentWritState >= writStates.decision" />
              <span>Решение</span>
            </div>
          </li>
          <li
            class="col step"
            :class="{
              selected: selectedState == writStates.execution,
              done: currentWritState >= writStates.execution,
            }"
            @click="selectState(writStates.execution)"
          >
            <div class="status"></div>
            <div>
              <CheckIcon v-if="currentWritState >= writStates.execution" />
              <span>Исполнение</span>
            </div>
          </li>
          <li
            class="col step last-arrow"
            :class="{
              selected: selectedState == writStates.closed,
              done: currentWritState == writStates.closed,
            }"
            @click="selectState(writStates.closed)"
          >
            <div>
              <CheckIcon v-if="currentWritState >= writStates.closed" />
              <span>Закрытие</span>
            </div>
          </li>
          <li class="col files" :class="{ selected: selectedState == writStates.files }" @click="selectState(writStates.files)">
            <div>
              <span>Файлы ({{ store.filesCount }})</span>
            </div>
          </li>
          <li class="col files" :class="{ selected: selectedState == writStates.payments }" @click="selectState(writStates.payments)">
            <div>
              <span>Платежи ({{ store.paymentsCount }})</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-body placeholder" style="min-height: 470px" v-if="isLoading"></div>
          <div class="card-body" v-if="!isLoading">
            <transition mode="out-in">
              <Preparation
                v-if="selectedState == writStates.preparation"
                :debtorId="debtorId"
                @generatePreparationDocument="generatePreparationDocument"
              />
              <Filing v-else-if="selectedState == writStates.filing" :debtorId="debtorId" />
              <Decision v-else-if="selectedState == writStates.decision" :debtorId="debtorId" />
              <Execution v-else-if="selectedState == writStates.execution" @generateExecutionDocument="generateExecutionDocument" />
              <Closed v-else-if="selectedState == writStates.closed" :debtorId="debtorId" />
              <Files v-else-if="selectedState == writStates.files" :debtorId="debtorId" />
              <Payments v-else :debtorId="debtorId" />
            </transition>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-6">
        <transition>
          <b-button variant="warning" class="btn-label waves-effect waves-light" @click="saveWrit">
            <span class="label-icon">
              <SaveIcon class="i" />
            </span>
            <span class="align-middle"> Сохранить изменения </span>
          </b-button>
        </transition>
      </div>
      <div class="col-6">
        <div class="float-end">
          <b-button
            variant="primary"
            class="btn-label waves-effect waves-light me-4"
            v-if="selectedState != writStates.preparation"
            @click="selectState(selectedState > writStates.preparation ? selectedState - 1 : selectedState)"
          >
            <i class="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
            Назад
          </b-button>

          <b-button
            variant="primary"
            class="btn-label waves-effect right waves-light"
            v-if="selectedState != writStates.files"
            @click="selectState(selectedState < writStates.files ? selectedState + 1 : selectedState)"
          >
            <i class="ri-arrow-right-line label-icon align-middle fs-16"></i>
            Вперед
          </b-button>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { ref, provide } from "vue";
import { mapGetters, mapActions } from "vuex";
import { authComputed } from "@/state/helpers";
import { useWritCardStore } from "../../state/writ.card";
import { useWritCardExecutionStore } from "../../state/writ.card.execution";
import { storeToRefs } from "pinia";
import { formatNumber } from "devextreme/localization";
import { CheckIcon, SaveIcon } from "@zhuowenli/vue-feather-icons";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";

import Preparation from "@/components/writ-card/_1preparation";
import Filing from "@/components/writ-card/_2filing";
import Decision from "@/components/writ-card/_3decision";
import Execution from "@/components/writ-card/_4execution";
import Closed from "@/components/writ-card/_5closed";
import Files from "@/components/writ-card/files";
import Payments from "@/components/writ-card/payments";
import DebtSummaryText from "../../components/debtor-card/debtSummaryText.vue";

export default {
  components: {
    Layout,
    PageHeader,
    Preparation,
    Filing,
    Decision,
    Execution,
    Closed,
    Files,
    Payments,
    CheckIcon,
    SaveIcon,
    DebtSummaryText,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    debtorId: {
      type: [Number, String],
      required: true,
    },
  },
  setup(props) {
    var isLoading = ref(false);
    provide("isLoading", isLoading);

    const store = useWritCardStore();
    if (props.id == 0) {
      store.reset(props.debtorId);
    }

    const executionStore = useWritCardExecutionStore();

    const { writ, currentWritState, error, writStates } = storeToRefs(store);

    return { isLoading, store, currentWritState, executionStore, writ, error, writStates };
  },
  async mounted() {
    if (!this.hasRightAddEditWrit) {
      this.$router.push({
        name: "debtor-card",
        params: { id: this.debtorId },
      });
    } else {
      await this.startLoadCard();
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      title: "debtorCardInfo/title",
      address: "debtorCardInfo/address",
      debtorData: "debtorCardInfo/debtorData",
      debtsData: "debtorCardDebtsAndPaymentsData/debtsData",
    }),
  },
  data() {
    return {
      selectedState: 1,
      errors: [],
      items: [
        { text: "Главная", href: "/" },
        { text: "Должники", href: "/debtors" },
        { text: "Должник", href: "/debtor-card/" + this.debtorId },
        { text: "Иски", href: "/debtor-card/" + this.debtorId + "/#3" },
        { text: "Карточка иска", active: true },
      ],
    };
  },

  methods: {
    ...mapActions({
      loadDebtorInfo: "debtorCardInfo/load",
      getSummaryReport: "debtorCardInfo/getSummaryReport",
      loadDebtsPaymentsData: "debtorCardDebtsAndPaymentsData/load",
    }),

    f(o) {
      return formatNumber(o, { type: "fixedPoint", precision: 2 });
    },

    selectState(state) {
      this.selectedState = state;
    },
    updateQueryParams() {
      this.$router.push({
        name: "writ-card",
        params: { id: this.writ.id, debtorId: this.debtorId },
      });
    },
    async startLoadCard() {
      this.isLoading = true;
      await this.loadDebtorInfo(this.debtorId);
      await this.loadDebtsPaymentsData(this.debtorId);
      if (this.id != 0) {
        await this.loadWritInfo(this.id);
      }
      this.isLoading = false;
    },
    async loadWritInfo() {
      try {
        this.errors = null;
        await this.store.load(this.debtorId, this.id);
        await this.executionStore.load(this.id);
      } catch (error) {
        console.log(error.response);
      }
    },
    async saveWrit() {
      try {
        this.errors = null;
        await this.store.save();
        if (this.id == 0) {
          this.updateQueryParams();
        }
        await this.loadWritInfo();
      } catch (error) {
        console.log(error);
        this.errors = this.error;
        //
      }
      this.isLoading = false;
    },
    async generatePreparationDocument() {
      await this.saveWrit();
      await this.store.generatePreparationDocument();
    },
    async generateExecutionDocument(item) {
      await this.saveWrit();
      await this.store.generateExecutionDocument(item.inhabitantId, item.id);
    },
    async startDownloadSummaryReport() {
      try {
        this.isLoading = true;
        await this.getSummaryReport();
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style>
:root {
  --step-bg: #dfe1e3;
  --step-color: #999999;
  --step-bg-hover: #b8b8b8;

  --step-done-bg: #13c56b;
  --step-done-bg-hover: #10a75b;

  --step-selected-color: #e8bc52;

  --files-bg: #6acce9;
  --files-bg-hover: #44a6c4;
}

label {
  margin-bottom: 3px;
}

.status {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% + 17px);
  height: 100%;
  z-index: 9;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.state-controls button {
  width: 130px;
}

.step > div,
.files > div {
  padding: 10px 10px 10px 30px;
}

.step.selected > div:after,
.files.selected > div:after {
  content: " ";
  position: absolute;
  left: 5%;
  bottom: -8px;
  height: 4px;
  width: 90%;
  background-color: var(--step-selected-color);
  border-radius: 2px;
}

ul > li.step:first-child:before,
.last-arrow:after {
  content: none !important;
}

.arrow-steps .step,
.files {
  height: 40px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  margin: 0 3px;
  padding: 0;
  /* padding: 10px 10px 10px 30px; */
  min-width: 180px;
  float: left;
  position: relative;
  background-color: var(--step-bg);
  color: var(--step-color);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 1s ease;
}

.files {
  background-color: var(--files-bg);
  border-radius: 5px;
  color: #fff;
}

.files > div {
  padding: 10px 10px 10px 10px;
}

.files:hover {
  background-color: var(--files-bg-hover);
  color: #fff;
}

.arrow-steps .step.done {
  background-color: var(--step-done-bg);
  color: #fff;
}

.arrow-steps .step.done:after {
  border-left: 17px solid var(--step-done-bg);
  color: #fff;
}

.arrow-steps .step.done:hover {
  background-color: var(--step-done-bg-hover);
  color: #fff;
}

.arrow-steps .step.done:hover:after {
  border-left: 17px solid var(--step-done-bg-hover);
  color: #fff;
}

.step:hover {
  background-color: var(--step-bg-hover);
  color: #fff;
}

.arrow-steps .step:hover:after {
  border-left: 17px solid var(--step-bg-hover);
  color: #fff;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -16px;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 17px solid var(--step-bg);
  z-index: 2;
  transition: all 1s ease;
}

.arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 17px solid #f3f6f9;
  z-index: 0;
}

.arrow-steps .step:first-child:before {
  border: none;
}

.last-arrow {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.arrow-steps .step:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
  position: relative;
}

.arrow-steps .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
  color: #fff;
  background-color: #094074;
}

.arrow-steps .step.current:after {
  border-left: 17px solid #094074;
}

.filing .row,
.preparation .row {
  /* margin-bottom: 10px; */
}

@media screen and (max-width: 1366px) {
  .col.files,
  .col.step {
    padding-left: 0;
    margin-left: 0;
    margin-top: 15px;
  }
}
</style>
