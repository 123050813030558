<template>
  <div>
    <div class="d-flex align-items-center">
      <div class="flex-grow-1 overflow-hidden">
        <p class="text-uppercase fw-medium text-muted mb-0">{{ itemInternal.name }}</p>
      </div>
    </div>
    <div class="card-text">
      Номер испол. листа:
      <strong>{{ item.executionDocNo }}</strong>
    </div>
    <div class="card-text">Примечание:</div>
    <div class="card-text mb-3">
      <strong>{{ item.current.notes }}</strong>
    </div>
  </div>
</template>

<script>
import { formatDate, formatNumber } from "devextreme/localization";
export default {
  props: {
    item: {
      type: Object,
    },
  },
  emits: ["cancel", "save"],
  data() {
    return {
      formatDate,
      itemInternal: this.item,
    };
  },
  methods: {
    fd(date) {
      return formatDate(new Date(date), "shortDate");
    },
    f(o) {
      return formatNumber(o, { type: "fixedPoint", precision: 2 });
    },
  },
};
</script>

<style></style>
