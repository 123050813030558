<template>
  <div class="filing">
    <div class="alert alert-primary alert-border-left alert-dismissible fade show" role="alert">
      <strong>2. Подача</strong>
    </div>

    <div class="form-row row">
      <div class="form-group col-md-4 mb-2">
        <label>Дата подачи:</label>
        <DxDateBox v-model="writ.filingDate" :show-clear-button="true" :use-mask-behavior="true" placeholder="введите или выберите" />
      </div>
      <div class="form-group col-md-4 mb-2">
        <label>Дата возврата:</label>
        <DxDateBox :show-clear-button="true" v-model="writ.filingReturnDate" :use-mask-behavior="true" placeholder="введите или выберите" />
      </div>
      <div class="form-group col-md-4 mb-2">
        <label>Дата переподачи:</label>
        <DxDateBox :show-clear-button="true" v-model="writ.refilingDate" :use-mask-behavior="true" placeholder="введите или выберите" />
      </div>
    </div>
  </div>
</template>

<script>
import { useWritCardStore } from "../../state/writ.card";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const store = useWritCardStore();
    const { writ } = storeToRefs(store);

    return { writ };
  },
};
</script>

<style scoped>
span {
  min-width: 150px;
}
</style>
