<template>
  <div>
    <div class="d-flex align-items-center">
      <div class="flex-grow-1 overflow-hidden">
        <p class="text-uppercase fw-medium text-muted text-truncate mb-0">{{ item.name }}</p>
      </div>
    </div>
    <div class="card-text">
      Сумма:
      <strong>{{ f(item.current.deliveredSum) }} {{ dSolidarity }}</strong>
    </div>
    <div class="card-text">
      Гос. пошлина:
      <strong>{{ f(item.current.stateFee) }} {{ sSolidarity }}</strong>
    </div>
    <div class="card-text">
      Банк:
      <strong>{{ item.current.data.bankName }}</strong>
    </div>
    <div class="card-text">Примечание:</div>
    <div class="card-text mb-3">
      <strong>{{ item.current.notes }}</strong>
    </div>
    <div class="d-flex align-items-center">
      <div class="flex-shrink-0 overflow-hidden">
        <div class="card-text">Документ:</div>
      </div>
      <div class="flex-grow-1 ms-2" v-if="itemInternal.current.documentId">
        <DxButton class="btn-label b primary p-2" @click="$emit('download', itemInternal)">
          <div class="d-flex">
            <span class="label-icon"> <DownloadIcon class="i" /> </span>
            <span class="ms-4"> Скачать заявление </span>
          </div>
        </DxButton>
        <div class="align-middle text-success"> сформировано {{ fdt(itemInternal.current.document.created) }} </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate, formatNumber } from "devextreme/localization";
import { DownloadIcon } from "@zhuowenli/vue-feather-icons";

export default {
  props: {
    item: {
      type: Object,
    },
  },
  emits: ["download"],
  components: { DownloadIcon },
  computed: {
    dSolidarity: {
      get() {
        return this.item.current.deliveredSumIsSolidarity ? "солидарно" : "по долям";
      },
    },
    sSolidarity: {
      get() {
        return this.item.current.stateFeeIsSolidarity ? "солидарно" : "по долям";
      },
    },
  },
  data() {
    return {
      formatDate,
      itemInternal: this.item,
    };
  },
  methods: {
    fd(date) {
      return formatDate(new Date(date), "shortDate");
    },
    f(o) {
      return formatNumber(o, { type: "fixedPoint", precision: 2 });
    },
    fdt(date) {
      return formatDate(new Date(date), "shortDateShortTime");
    },
  },
};
</script>

<style></style>
