<template>
  <div class="filing">
    <div class="alert alert-primary alert-border-left alert-dismissible fade show" role="alert">
      <strong>5. Решение</strong>
    </div>
    <div class="row">
      <div class="col-xl-1 d-flex align-items-center">
        <DxCheckBox v-model:value="writ.isClosed" />
        <span class="ms-2">Закрыт</span>
      </div>
      <div class="col-xl-6 d-flex align-items-center">
        <span class="pe-2 text-nowrap">по причине:</span>
        <DxTextArea class="w-100" :show-clear-button="true" v-model="writ.closeReason" />
      </div>
    </div>
    <div class="row">
      <div class="col-xl-1 d-flex align-items-center">
        <DxCheckBox v-model:value="writ.isArchived" />
        <span class="ms-2">Отправлено в архив</span>
      </div>
    </div>
  </div>
</template>

<script>
import DxTextArea from "devextreme-vue/text-area";
import { useWritCardStore } from "../../state/writ.card";
import { storeToRefs } from "pinia";
export default {
  components: { DxTextArea },
  setup() {
    const store = useWritCardStore();
    const { writ } = storeToRefs(store);

    return { writ };
  },
};
</script>

<style scoped>
span {
  min-width: 150px;
}
</style>
