import axios from "axios";
import { defineStore } from "pinia";
import notify from "devextreme/ui/notify";

const updateUrl = `${process.env.VUE_APP_URL}/api/writs/create-or-update`;
const getUrl = `${process.env.VUE_APP_URL}/api/writs/writ/`;

export const useWritCardStore = defineStore({
    id: "writCard1",
    state: () => ({
        isLoading: false,
        error: [],

        debtorId: null,
        writStates: {
            preparation: 1,
            filing: 2,
            decision: 3,
            execution: 4,
            closed: 5,
            files: 6,
            payments: 7,
        },
        writ: {
            id: 0,
            debtorId: null,

            preparationDate: new Date(),
            preparationTemplateId: null,
            preparationDocumentId: null,
            preparationApplicationFormId: 1,
            preparationCourtNumber: null,
            preparationPeriodStart: null,
            preparationPeriodEnd: null,
            preparationPeriodDetails: null,
            preparationSumToCollect: null,
            preparationStateFee: null,
            preparationPenalties: null,
            preparationNote: null,
            preparationOwnershipPart: null,
            preparationRespondents: [],
            writCourtStateFeeDetails: null,
            filingDate: null,
            filingReturnDate: null,
            refilingDate: null,
            courtDecisionExecutionDocNo: null,
            courtDecisionType: null,
            courtDecisionTypeId: null,
            courtDecisionDate: null,
            courtDecisionIsReturn: false,
            courtDecisionIsCancel: false,
            courtDecisionCancelDate: null,
            courtDecisionReturnDate: null,
            courtDecisionSum: null,
            courtDecisionStateFee: null,
            courtDecisionPenalties: null,
            courtDecisionReceivingExecDocDate: null,
            courtDecisionReceivingDate: null,
            courtDecisionComment: null,
            executionDocuments: [],
            closeDate: null,
            closeReason: null,
            isClosed: false,
            isArchived: false,
            files: [],
            writStateId: 1,
        }
    }),
    getters: {
        currentWritState(state) {
            return state.writ.writStateId;
        },
        isPreparation(state) {
            return this.writStateId == state.writStates.preparation;
        },
        isFiling(state) {
            return this.writStateId == state.writStates.filing;
        },
        isDecision(state) {
            return this.writStateId == state.writStates.decision;
        },
        isExecution(state) {
            return this.writStateId == state.writStates.execution;
        },
        isClosed(state) {
            return this.writStateId == state.writStates.closed;
        },

        canGenerateDoc(state) {
            if (state.writ && state.writ.preparationTemplateId) return true;
            return false;
        },

        preparationDocument(state) {
            return state.writ && state.writ.preparationDocument ? state.writ.preparationDocument.file.fileName : "не задан";
        },

        filesCount(state) {
            return state.writ && state.writ.files ? state.writ.files.length : 0;
        },

        paymentsCount(state) {
            return state.writ.paymentsByWritCount;
        },

        writState(state) {
            return state.writ.writState;
        },


        executionDocumentsOffice(state) {
            return state.writ.executionDocuments.filter((r) => r.current.data.dataType == 1);
        },
        executionDocumentsBank(state) {
            return state.writ.executionDocuments.filter((r) => r.current.data.dataType == 2);
        },

        executionDocumentsPfr(state) {
            return state.writ.executionDocuments.filter((r) => r.current.data.dataType == 3);
        },

        executionDocumentsSsp(state) {
            return state.writ.executionDocuments.filter((r) => r.current.data.dataType == 4);
        },

    },
    actions: {
        resetError() {
            this.error = [];
        },
        reset(debtorId) {
            this.$reset();
            this.debtorId = debtorId;
            this.writ.debtorId = debtorId;
        },

        async new(debtorId) {
            this.resetError();
            this.$reset();
            this.debtorId = debtorId;
        },

        async load(debtorId, writId) {
            this.resetError();
            // this.$reset();
            this.loading = true;
            try {
                var response = await axios.get(getUrl + writId);
                var data = response.data;
                this.debtorId = debtorId;
                this.writ = data;
            } catch (error) {
                this.error = [error];
                throw error;
            }
        },

        async save() {
            try {
                this.resetError();
                this.isLoading = true;
                let response = await axios.post(updateUrl, this.writ);
                this.writ.id = response.data.id;
                this.writ.writState = response.data.writState;

                notify({
                    message: "Сохранено",
                    height: 40,
                    width: 120,
                    minWidth: 120,
                    position: {
                        my: "bottom left",
                        at: "bottom left",
                        offset: '20 -20'
                    },
                    displayTime: 3500
                }, "success");

            } catch (error) {
                let response = error.response;
                if (response.data.errors) {
                    // if exists error list
                    var errors = response.data.errors;
                    for (var key of Object.keys(errors)) {
                        errors[key].map((e) => {
                            this.error.push(e);
                        });
                    }

                } else {
                    // if single error
                    this.error.push({ message: response.data.title });
                }
                throw this.error;
            }
            this.isLoading = false;
        },

        async calculateFee() {
            var sum = 0;
            var isOrder = false;

            if (this.writ.preparationSumToCollect) {
                sum = this.writ.preparationSumToCollect;
            }

            if (this.writ.preparationApplicationFormId == 1 /*О вынесении судебного приказа*/) {
                isOrder = true;
            }

            var response = await axios.get(`${process.env.VUE_APP_URL}/api/Writs/calculate-writ-state-fee/${isOrder}/${sum}`);
            this.writ.preparationStateFee = response.data;
        },


        async generatePreparationDocument() {
            const response = await axios.post(`${process.env.VUE_APP_URL}/api/writs/generate-preparation-document/${this.writ.id}`, {
                id: this.writ.id,
            });

            this.writ.preparationDocumentId = response.data.id;
            this.writ.preparationDocument = response.data;

            notify({
                message: "Документ сформирован",
                height: 30,
                width: 120,
                minWidth: 120,
                position: {
                    my: "bottom left",
                    at: "bottom left",
                    offset: '20 -20'
                },
                displayTime: 3500
            }, "success");

        },

        async generateExecutionDocument(inhabitantId, moveId) {
            const response = await axios.post(`${process.env.VUE_APP_URL}/api/writs/generate-execution-document/${this.writ.id}/${inhabitantId}/${moveId}`, {
                id: this.writ.id,
                inhabitantId: inhabitantId,
                moveId: moveId
            });

            console.log(response);
            // const move = this.writ.executionDocuments.filter((r) => r.inhabitantId == inhabitantId && r.current.id == moveId)[0];
            // move.documentId = response.data.id;
        },

        setPaymentsCount(count) {
            if (this.writ) {
                this.writ.paymentsByWritCount = count;
            }
        }

    },
});
