<template>
  <div class="preparation">
    <div class="alert alert-primary alert-border-left alert-dismissible fade show" role="alert">
      <strong>1. Подготовка</strong>
    </div>

    <div class="form-row row">
      <div class="form-group col-md-4 mb-2">
        <label>Дата составления:</label>
        <DxDateBox :show-clear-button="true" v-model="writ.preparationDate" :use-mask-behavior="true" placeholder="введите или выберите" />
      </div>
      <div class="form-group col-md-4 mb-2">
        <label>Форма заявления:</label>
        <DxSelectBox
          ref="cmbpreparationApplicationForms"
          v-model="writ.preparationApplicationFormId"
          @value-changed="onApplicationFormChanged"
          display-expr="name"
          value-expr="id"
          :data-source="preparationApplicationFormsStore"
          :show-clear-button="true"
          class="w-100"
        ></DxSelectBox>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-4 mb-2 d-flex align-items-center">
        <span class="pe-2">Судебный участок:</span>
        <div class="form-check form-radio-primary">
          <input
            class="form-check-input"
            type="radio"
            name="radio"
            id="radio1"
            v-model="stateFeeDetails"
            :value="0"
            @click="resetWritCourtStateFeeDetails"
          />
          <label class="form-check-label align-middle" for="radio1" @click="resetWritCourtStateFeeDetails">
            {{ houseCourt }}
          </label>
        </div>
      </div>
      <div class="col-xl-4 mb-2 d-flex align-items-center">
        <div class="form-check form-radio-primary">
          <input
            class="form-check-input"
            type="radio"
            name="radio"
            id="radio2"
            :value="1"
            v-model="stateFeeDetails"
            @change="showPopup = true"
          />
          <label class="form-check-label align-middle" for="radio2">
            Вручную:
            {{ writCourtStateFeeDetails.number }}
          </label>
        </div>
        <DxButton text="Ввести реквизиты" class="ms-2 b secondary" @click="showPopup = true" :disabled="stateFeeDetails != 1" />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-xl-12 d-flex align-items-center">
        <div>
          <DxDataGrid
            ref="gvPreparationRespondents"
            dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
            :show-borders="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :remote-operations="false"
            :data-source="writ.preparationRespondents"
            @toolbar-preparing="onToolbarPreparing($event)"
          >
            <DxColumn :width="125" data-field="inhabitantId" caption="ФИО" cell-template="nameWithEditButton">
              <DxRequiredRule :message="'Поле обязательное'" />
              <DxLookup :data-source="inhabitantsStore" display-expr="name" value-expr="id" />
          <DxFormItem :col-span="2" />
            </DxColumn>
            <template #nameWithEditButton="{ data: cellInfo }">
              <div class="d-flex gap-2">
                {{ cellInfo.displayValue }}
                <a href="#" @click.prevent="startEditInhabitant(cellInfo.data.inhabitantId)">Редактировать жителя </a>
              </div>
            </template>

            <DxScrolling row-rendering-mode="virtual" />
            <DxEditing :allow-adding="true" :allow-updating="true" :allow-deleting="true" mode="popup">
              <DxPopup height="auto" width="800" />
              <DxTexts edit-row="Заменить" />
            </DxEditing>
            <DxPaging :page-size="10" />
            <DxPager
              :visible="true"
              :allowed-page-sizes="[5, 10, 20]"
              :show-page-size-selector="true"
              :show-info="true"
              :show-navigation-buttons="true"
            />
          </DxDataGrid>
        </div>
      </div>
    </div>

    <div class="form-row row">
      <div class="form-group col-md-4 mb-2">
        <label>Начало периода:</label>
        <DxDateBox
          class="w-100"
          :show-clear-button="true"
          v-model="writ.preparationPeriodStart"
          :use-mask-behavior="true"
          placeholder="введите или выберите"
        />
      </div>
      <div class="form-group col-md-4 mb-2">
        <label>Окончание периода:</label>
        <DxDateBox
          class="w-100"
          :show-clear-button="true"
          v-model="writ.preparationPeriodEnd"
          :use-mask-behavior="true"
          placeholder="введите или выберите"
        />
      </div>
      <div class="form-group col-md-4 mb-2">
        <label>Детализация периодов:</label>
        <DxTextBox class="w-100" :show-clear-button="true" v-model="writ.preparationPeriodDetails" />
      </div>
    </div>

    <div class="form-row row">
      <div class="form-group col-md-4 mb-2">
        <label>Сумма к взысканию:</label>
        <DxNumberBox class="w-100" :show-clear-button="true" v-model="writ.preparationSumToCollect" format="#,##0.00" />
      </div>
      <div class="form-group col-md-4 mb-2">
        <label>Гос. пошлина:</label>
        <div class="d-flex">
          <DxNumberBox class="w-100 me-2" :show-clear-button="true" v-model="writ.preparationStateFee" format="#,##0.00" />
          <DxButton text="Рассчитать" @click="calculateFee" />
        </div>
      </div>
      <div class="form-group col-md-4 mb-2">
        <label>Пени:</label>
        <DxNumberBox class="w-100" :show-clear-button="true" v-model="writ.preparationPenalties" format="#,##0.00" />
      </div>
    </div>

    <div class="form-row row">
      <div class="form-group col-md-4 mb-2">
        <label>Доля в долевой собственности:</label>
        <DxTextBox class="w-100" :show-clear-button="true" v-model="writ.preparationOwnershipPart" />
      </div>
    </div>

    <div class="form-row row">
      <div class="form-group col-lg-12 col-md-12 mb-2">
        <label>Примечание:</label>
        <DxTextArea class="w-100" :show-clear-button="true" v-model="writ.preparationNote" :height="60" />
      </div>
    </div>

    <div class="form-row row">
      <div class="form-group col-lg-8 col-md-12 mb-2">
        <label>Шаблон:</label>
        <DxSelectBox
          ref="cmbTemplates"
          v-model="writ.preparationTemplateId"
          display-expr="name"
          value-expr="fileId"
          :data-source="templatesStore"
          :show-clear-button="true"
          class="w-100"
        ></DxSelectBox>
      </div>
    </div>

    <div class="form-row row" v-if="writ.preparationDocumentId">
      <div class="form-group col-md-8 mb-2">
        <b-button variant="secondary2" class="btn-label me-4 b primary" @click.prevent="downloadPreparationDocument">
          <span class="label-icon">
            <DownloadIcon class="i" />
          </span>
          <span class="align-middle"> Скачать заявление </span>
        </b-button>
        <span class="align-middle text-success"> сформировано {{ fd(writ.preparationDocument.created) }} </span>
      </div>
    </div>

    <div class="form-row row" v-if="store.canGenerateDoc">
      <div class="form-group col-md-8">
        <b-button variant="secondary2" class="btn-label me-4 b primary" @click="generatePreparationDocument">
          <span class="label-icon">
            <FileTextIcon class="i" />
          </span>
          <span class="align-middle">Сформировать печатную форму заявления </span>
        </b-button>
      </div>
    </div>

    <div>
      <DxPopupModal
        id="courtStateFeeDetailts"
        ref="dxPopup"
        :visible="showPopup"
        :drag-enabled="true"
        :close-on-outside-click="true"
        :show-close-button="true"
        :show-title="true"
        :width="600"
        title="Судебный участок"
        height="auto"
        @hidden="showPopup = false"
      >
        <DxPopupPosition at="center" my="center" />
        <div class="row">
          <div class="col-md-6">
            <div class="">
              <label class="form-label mb-0">судебный участок:</label>
              <input type="text" class="form-control form-control-sm" v-model="writCourtStateFeeDetails.number" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="">
              <label class="form-label mb-0">адрес судебного участка:</label>
              <input type="text" class="form-control form-control-sm" v-model="writCourtStateFeeDetails.address" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="mt-3">
              <label class="form-label">реквизиты для оплаты гос.пошлины:</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-1">
              <label class="form-label mb-0">наименование получателя платежа:</label>
              <input type="text" class="form-control form-control-sm" v-model="writCourtStateFeeDetails.recipientName" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-1">
              <label class="form-label mb-0">счет получателя платежа:</label>
              <input type="text" class="form-control form-control-sm" v-model="writCourtStateFeeDetails.recipientAccount" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-1">
              <label class="form-label mb-0">инн получателя:</label>
              <input type="text" class="form-control form-control-sm" v-model="writCourtStateFeeDetails.recipientINN" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-1">
              <label class="form-label mb-0">кпп получателя:</label>
              <input type="text" class="form-control form-control-sm" v-model="writCourtStateFeeDetails.recipientKPK" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-1">
              <label class="form-label mb-0">банк получателя:</label>
              <input type="text" class="form-control form-control-sm" v-model="writCourtStateFeeDetails.recipientBank" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-1">
              <label class="form-label mb-0">город банка получателя:</label>
              <input type="text" class="form-control form-control-sm" v-model="writCourtStateFeeDetails.recipientBankCity" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-1">
              <label class="form-label mb-0">бик банка получателя:</label>
              <input type="text" class="form-control form-control-sm" v-model="writCourtStateFeeDetails.recipientBankBIK" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-1">
              <label class="form-label mb-0">казначейский счет:</label>
              <input type="text" class="form-control form-control-sm" v-model="writCourtStateFeeDetails.treasuryAccount" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-1">
              <label class="form-label mb-0">кбк:</label>
              <input type="text" class="form-control form-control-sm" v-model="writCourtStateFeeDetails.kbk" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-1">
              <label class="form-label mb-0">октмо:</label>
              <input type="text" class="form-control form-control-sm" v-model="writCourtStateFeeDetails.oktmo" />
            </div>
          </div>
          <div class="col-md-12 text-end">
            <DxButton text="Сохранить" type="success" @click="saveWritCourtStateFeeDetails" />
          </div>
        </div>
      </DxPopupModal>
    </div>
    <CreateOrUpdateInhabitant
      :debtorId="debtorId"
      :debtor-data="debtorData"
      :id="inhabitantToEditId"
      @on-inhabitant-saved="onInhabitantSaved"
      @on-inhabitant-cancel="onInhabitantSaveCancel"
    />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { authComputed } from "@/state/helpers";
import createStoreExtend from "@/helpers/grid/store";
import { confirm } from "devextreme/ui/dialog";
import { preparationApplicationFormsStore } from "@/helpers/common-store/store";
import DxTextArea from "devextreme-vue/text-area";
import { DxPopup, DxTexts, DxFormItem } from "devextreme-vue/data-grid";
import CreateOrUpdateInhabitant from "@/components/forms/create-or-update-inhabitant.vue";
import { FileTextIcon, DownloadIcon } from "@zhuowenli/vue-feather-icons";

import { useWritCardStore } from "../../state/writ.card";
import { storeToRefs } from "pinia";
import { formatDate } from "devextreme/localization";

export default {
  emits: ["generatePreparationDocument"],
  components: {
    DxPopup,
    DxTexts,
    DxTextArea,
    CreateOrUpdateInhabitant,
    FileTextIcon,
    DownloadIcon,
    DxFormItem,
  },
  props: {
    debtorId: {
      type: [Number, String],
    },
  },
  setup() {
    const store = useWritCardStore();
    const { writ } = storeToRefs(store);

    return { store, writ };
  },
  mounted() {
    if (this.writ.writCourtStateFeeDetails != null) {
      this.writCourtStateFeeDetails = JSON.parse(JSON.stringify(this.writ.writCourtStateFeeDetails));
      this.stateFeeDetails = 1;
    }
    this.preparationRespondents = this.writ.preparationRespondents;
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      debtorData: "debtorCardInfo/debtorData",
    }),

    houseCourt: {
      get() {
        return this.debtorData.house && this.debtorData.house.court ? this.debtorData.house.court.number : "не задан";
      },
    },

    courtId: {
      get() {
        return this.debtorData.house && this.debtorData.house.court ? this.debtorData.house.court.id : 0;
      },
    },

    applicationFormId: {
      get() {
        return this.$refs.cmbpreparationApplicationForms.instance.option("value");
      },
    },
  },
  data() {
    return {
      showPopup: false,
      inbitants: [],
      inhabitantToEditId: null,
      preparationRespondents: [],
      inhabitantsStore: createStoreExtend({
        key: "id",
        loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/inhabitants/inhabitants-by-debtor`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.data.debtorId = this.debtorId;
        },
        onLoaded: (result) => {
          this.preparationRespondentsArray = result;
          // set names when writ loaded
          for (var i = 0; i < this.preparationRespondents.length; i++) {
            this.setRespondentName(this.preparationRespondents[i]);
          }
        },
      }),
      templatesStore: createStoreExtend({
        key: "fileId",
        loadUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_COURTS_GET_TEMPLATES}`,
        onBeforeSend: (method, ajaxOptions) => {
          // ajaxOptions.data.applicationFormId = this.applicationFormId;
          ajaxOptions.data.applicationFormId = this.writ.preparationApplicationFormId;
          ajaxOptions.data.courtId = this.courtId;
        },
      }),
      preparationRespondentsArray: [],
      preparationApplicationFormsStore,
      stateFeeDetails: 0,
      prepid: -1,
      writCourtStateFeeDetails: {
        number: null,
        address: null,
        recipientName: null,
        recipientAccount: null,
        recipientINN: null,
        recipientKPK: null,
        recipientBank: null,
        recipientBankCity: null,
        recipientBankBIK: null,
        treasuryAccount: null,
        kbk: null,
        oktmo: null,
      },
    };
  },
  methods: {
    startEditInhabitant(id) {
      this.inhabitantToEditId = id;
    },
    onInhabitantSaved() {
      this.inhabitantToEditId = null;
      this.$refs["gvPreparationRespondents"].instance.refresh();
    },
    onInhabitantSaveCancel() {
      this.inhabitantToEditId = null;
    },
    onApplicationFormChanged() {
      this.$refs.cmbTemplates.instance.getDataSource().reload();
    },
    resetWritCourtStateFeeDetails() {
      this.writCourtStateFeeDetails = {
        number: null,
        address: null,
        recipientName: null,
        recipientAccount: null,
        recipientINN: null,
        recipientKPK: null,
        recipientBank: null,
        recipientBankCity: null,
        recipientBankBIK: null,
        treasuryAccount: null,
        kbk: null,
        oktmo: null,
      };
      this.writ.writCourtStateFeeDetails = null;
    },
    saveWritCourtStateFeeDetails() {
      this.writ.writCourtStateFeeDetails = this.writCourtStateFeeDetails;
      this.showPopup = false;
    },
    setRespondentName(respondent) {
      var item = this.preparationRespondentsArray.filter((r) => r.id == respondent.inhabitantId)[0];
      respondent.name = item.name;
    },
    onToolbarPreparing(e) {
      let toolbarItems = e.toolbarOptions.items;
      let self = this;

      let addRowButton = toolbarItems.find((x) => x.name === "addRowButton");
      addRowButton.options.text = "Добавить ответчика";
      addRowButton.showText = "always";
      addRowButton.options.elementAttr = {
        class: "b primary",
      };

      toolbarItems.push({
        location: "before",
        template: function () {
          return "<h6 class='pe-2 mb-0 text-nowrap'>Ответчики:</h6>";
        },
      });

      if (this.hasRightAddEditInhabitant) {
        toolbarItems.push({
          widget: "dxButton",
          options: {
            // visible: false,
            icon: "plus",
            text: "Добавить жителя к лиц. счету",
            onClick: function () {
              self.inhabitantToEditId = 0;
            },
            elementAttr: {
              class: "b secondary",
            },
          },
          location: "after",
        });
      }
    },
    fillInhabitants() {
      axios
        .get(`${process.env.VUE_APP_URL}/api/inhabitants/writ-respondents`)
        .then((response) => {
          this.inhabitants = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    async generatePreparationDocument() {
      let result = confirm(
        "<span class='fs-5 align-middle'>Вы уверены, что хотите заново сформировать и заменить существующее заявление?</span>",
        "Формирование документа"
      );
      result.then(async (dialogResult) => {
        if (dialogResult) {
          // that.store.generatePreparationDocument();
          this.$emit("generatePreparationDocument");
        }
      });
    },

    downloadPreparationDocument() {
      let self = this;
      axios
        .get(`${process.env.VUE_APP_URL}/api/Writs/preparation-document/${this.writ.id}`, { responseType: "arraybuffer" })
        .then(function (response) {
          console.log(response);
          var headers = response.headers;
          var blob = new Blob([response.data], {
            type: headers["content-type"],
          });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          let buildingNumber = "";
          if (self.debtorData.house.buildingNumber != null) buildingNumber = `к.${self.debtorData.house.buildingNumber}_`;
          let fileName =
            `${self.debtorData.debtor.accountNumber}_${self.debtorData.house.streetName}_д.${self.debtorData.house.houseNumber}_${buildingNumber}кв.${self.debtorData.debtor.flatNumber}.docx`
              .replaceAll("\\", "")
              .replaceAll("/", "");
          link.download = fileName;
          link.click();
        });
    },

    async calculateFee() {
      await this.store.calculateFee();
    },
    fd(date) {
      return formatDate(new Date(date), "shortDateShortTime");
    },
  },
};
</script>

<style>
.i {
  width: 16px;
  height: 16px;
}
</style>
